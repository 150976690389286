import request from '@/utils/request'

// 顾客管理
// 账号列表
export const customerListAPI = (params) => {
  return request({
    url: '/customerList',
    method: 'GET',
    params
  })
}

// 冻结账号
export const deletecustomerAPI = (params) => {
  return request({
    url: '/staffDel',
    method: 'GET',
    params
  })
}
// 启用账号
export const startcustomerAPI = (params) => {
  return request({
    url: '/staffStart',
    method: 'GET',
    params
  })
}
// 导出
export const reportCustomerAPI = (params) => {
  return request({
    url: '/reportcustomer',
    method: 'GET',
    params
  })
}
